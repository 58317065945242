<template xmlns:v-swiper="http://www.w3.org/1999/xhtml">
  <div>
<!--    <div class="collection-sidebar-banner mb-3">
      <a href="#">
        <img :src="bannerimagepath" class="img-fluid"/>
      </a>
    </div>-->
    <div class="collection-filter">
      <!-- side-bar single product slider start -->
      <div class="theme-card">
        <h5 class="title-border">Nouveaux Produits</h5>
        <div class="offer-slider slide-1">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div>
                  <div class="media" v-for="(product,index) in getCategoryProduct('new').splice(0,3)"
                       :key="index">
                    <router-link :to="{ path: '/produit/'+product.slug}">
                      <img class="img-fluid"
                           :src="product.images.length > 0 ? product.images[0].original_url : ''"
                           alt>
                    </router-link>
                    <div class="media-body align-self-center">
                      <div class="rating">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                      <router-link :to="{ path: '/produit/'+product.slug}">
                        <h6>{{product.name}}</h6>
                      </router-link>
                      <h4 v-if="product.sale">
                        <del>{{ product.currency.symbol }} {{ product.price |
                            numeral("0,0.00")}}
                        </del>
                      </h4>
                      <h4 v-else>{{ product.currency.symbol }} {{ product.price |
                          numeral("0,0.00")
                        }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide" v-if="getCategoryProduct('new').length >= 4">
                <div>
                  <div class="media"
                       v-for="(product, index) in getCategoryProduct('new').splice(3,3)"
                       :key="index">
                    <router-link :to="{ path: '/produit/'+product.id}">
                      <img class="img-fluid"
                           :src="product.images.length > 0 ? product.images[0].original_url : ''"
                           alt>

                    </router-link>
                    <div class="media-body align-self-center">
                      <div class="rating">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                      <router-link :to="{ path: '/produit/'+product.slug}">
                        <h6>{{product.name}}</h6>
                      </router-link>
                      <h4 v-if="product.sale">
                        <del>{{ product.currency.symbol }} {{ product.price |
                            numeral("0,0.00")}}
                        </del>
                      </h4>
                      <h4 v-else>{{ product.currency.symbol }} {{ product.price |
                          numeral("0,0.00")
                        }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev" slot="button-prev">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- side-bar single product slider end -->
      <!-- side-bar banner start here -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "product-sidebar",

  data() {
    return {
      bannerimagepath: require("@/assets/images/side-banner.png"),

      swiperOption: {
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },

  computed: {
    ...mapState("products", ["products"]),
    ...mapGetters({
      filterbyCategory: "products/filterbyCategory"
    })
  },

  methods: {
    getCategoryProduct(collection) {
      return this.products.filter((item) => {
        if (item[collection]) {
          return item;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
