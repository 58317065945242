<template>
    <div>
        <section class="ratio_asos">
            <div class="container">
                <div class="col-12 product-related">
                    <h2>{{ title }}</h2>
                </div>
                <div class="row m-0">
                    <div
                            class="col-xl-2 col-md-4 col-sm-6"
                            v-for="(product,index) in productslist.slice(1, 7)"
                            :key="index"
                    >
                        <div class="product-box">
                            <productBox1
                                    @opencartmodel="showCart"
                                    @openquickview="showQuickview"
                                    @showalert="alert"
                                    @alertseconds="alert"
                                    :product="product"
                                    :index="index"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <b-alert
                :show="dismissCountDown"
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="alert"
        >
            <p>Product Is successfully added to your wishlist.</p>
        </b-alert>
        <quickviewModel :openModal="showquickviewmodel" :productData="quickviewproduct" />
        <compareModel :openCompare="showcomparemodal" :productData="comapreproduct" @closeCompare="closeCompareModal" />
        <cartModel :openCart="showcartmodal" :productData="cartproduct" @closeCart="closeCartModal" :products="products" />
    </div>
</template>

<script>
  import productMixin from "../../mixins/productMixins";

  export default {
    name: "related-product",

    mixins: [productMixin],
  };
</script>

<style scoped>

</style>