<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-swiper="http://www.w3.org/1999/xhtml">
  <div>
    <Header/>
    <Breadcrumbs :title="productDetails ? productDetails.name : ''"/>
    <section class="section-b-space">
      <div class="collection-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <productSidebar/>
            </div>
            <div class="col-lg-9 productdetail">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-6" v-if="productDetails && productDetails.type !== 'simple' && selectedVariant.images.length > 0">
                    <div v-swiper:mySwiper="swiperOption" ref="mySwiper">
                      <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(imag,index) in swiperImages"
                            :key="index"
                        >
                          <img
                              :src="imag.original_url"
                              :id="imag.id"
                              class="img-fluid bg-img"
                              :alt="productDetails.name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 slider-nav-images">
                        <div v-swiper:mySwiper1="swiperOption1">
                          <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(product,index) in swiperImages"
                                :key="index"
                            >
                              <img
                                  :src="product.original_url"
                                  :id="product.id"
                                  class="img-fluid bg-img"
                                  alt="product.alt"
                                  @click="slideTo(index)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6" v-else>
                    <div v-swiper:mySwiper="swiperOption" ref="mySwiper">
                      <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(imag,index) in productDetails.images"
                            :key="index"
                        >
                          <img
                              :src="imag.original_url"
                              :id="imag.id"
                              class="img-fluid bg-img"
                              :alt="productDetails.name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 slider-nav-images">
                        <div v-swiper:mySwiper1="swiperOption1">
                          <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(product,index) in productDetails.images"
                                :key="index"
                            >
                              <img
                                  :src="product.original_url"
                                  :id="product.id"
                                  class="img-fluid bg-img"
                                  alt="product.alt"
                                  @click="slideTo(index)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                      <h2>{{ productDetails.name }}</h2>
                      <h3 v-if="productDetails.type !== 'simple'">{{ selectedVariant.name }}</h3>
                      <h3 v-if="productDetails.show_price">
                        {{ productDetails.type!=='simple' ? selectedVariant.price :  productDetails.price | numeral('0,0.00')}}
                        {{ productDetails.type!=='simple' ? selectedVariant.currency.symbol :  productDetails.currency.symbol }}
                        <br/>
                        <small class="text-muted" v-if="productDetails.show_point">{{
                            productDetails.type!=='simple' ? selectedVariant.point : productDetails.point | numeral('0,0.00')}} Point(s)
                        </small>
                      </h3>
                      <h4 v-if="productDetails.show_discount">
                                                <span class="text-success">
                                                    {{ productDetails.type!=='simple' ? selectedVariant.discount_value :  productDetails.discount_value | numeral('0,0.00') }}
                                                    {{ productDetails.type!=='simple' ? selectedVariant.currency.symbol :  productDetails.currency.symbol }} Cashback</span>
                      </h4>


                      <vue-horizontal class="horizontal" snap="none" v-if="productDetails.type !=='simple'">
                        <div class="item"
                             v-for="(variant,variantIndex) in productDetails.variants"
                             :key="variantIndex">
                          <div class="ring"
                               :class="variantIndex === selectVariantIndex ? 'active' : ''"
                               @click="selectVariant(variant,variantIndex)">
                            <div class="avatar"
                                 :style="{background: `url(${variant.images.length > 0 ? variant.images[0].original_url : (productDetails.images.length > 0 ? productDetails.images[0].original_url : '')})`}">
                            </div>
                          </div>
                        </div>
                      </vue-horizontal>


                      <div class="product-description border-product">

                        <h5 class="avalibility"
                            v-if="(typeof(counter) == 'string' ? parseInt(counter) : counter) <= parseInt(productDetails.type !=='simple' ? selectedVariant.stock : productDetails.stock)">
                          <span>En Stock</span>
                        </h5>
                        <h5 class="avalibility"
                            v-if="(typeof(counter) == 'string' ? parseInt(counter) : counter) > parseInt(productDetails.type !=='simple' ? selectedVariant.stock : productDetails.stock)">
                          <span>Rupture de stock</span>
                        </h5>
                        <h6 class="product-title">quantité</h6>
                        <div class="qty-box">
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button
                                  type="button"
                                  class="btn quantity-left-minus"
                                  data-type="minus"
                                  data-field
                                  @click="decrement()"
                              >
                                <i class="ti-angle-left"></i>
                              </button>
                            </span>
                            <input
                                type="text"
                                name="quantity"
                                class="form-control input-number"
                                :disabled="+counter > (productDetails.type !=='simple' ? +selectedVariant.stock : +productDetails.stock)"
                                v-model="counter"
                            />
                            <span class="input-group-prepend">
                              <button
                                  type="button"
                                  class="btn quantity-right-plus"
                                  data-type="plus"
                                  data-field
                                  @click="increment()"
                              >
                                <i class="ti-angle-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="product-buttons">
                        <!--<router-link :to="{ path: '/dashboard/panier'}">-->
                          <button
                              class="btn btn-solid"
                              title="Add to cart"
                              @click="addToCart(productDetails.type !=='simple' ? selectedVariant : productDetails, counter)"
                              :disabled="(typeof(counter) == 'string' ? parseInt(counter) : counter) > parseInt(productDetails.type !=='simple' ? selectedVariant.stock : productDetails.stock)"
                          >Ajouter au panier
                          </button>
                        <!--</router-link>-->
                        <button
                            class="btn btn-solid"
                            title="buy now"
                            @click="buyNow(productDetails.type !=='simple' ? selectedVariant : productDetails, counter)"
                            :disabled="(typeof(counter) == 'string' ? parseInt(counter) : counter) > parseInt(productDetails.type !=='simple' ? selectedVariant.stock : productDetails.stock)"
                        >Acheter
                        </button>
                      </div>

                  <div class="border-product">
                        <!--<h6 class="product-title">Details</h6>-->
                        <p> {{ productDescription(productDetails.type!=='simple' ? selectedVariant.description : productDetails.description, 124) }} </p>
                      </div>

                      <div class="border-product">
                        <h6 class="product-title">Partagez</h6>
                        <div class="product-icon">
                          <ul class="product-social">
                            <ShareNetwork
                                    v-for="(lks, i) in shareLinks"
                                    :key="i"
                                    :network="lks.network"
                                    :url=selectedVariant.productLink
                                    :title="selectedVariant.name"
                                    :description="productDescription(selectedVariant.description, 300)"
                            >
                              <li class="p-2">
                                <a href="javascript:void(0)">
                                  <i :class="lks.icon"></i>
                                </a>
                              </li>
                            </ShareNetwork>
                          </ul>
                          <!--<form class="d-inline-block">-->
                          <!--<button class="wishlist-btn" @click="addToWishlist(productDetails.type !=='simple' ? selectedVariant : productDetails)">-->
                          <!--<i class="fa fa-heart"></i>-->
                          <!--<span class="title-font">Ajouter au WishList</span>-->
                          <!--</button>-->
                          <!--</form>-->
                        </div>
                      </div>
                      <!--<div class="border-product">-->
                      <!--<h6 class="product-title">Time Reminder</h6>-->
                      <!--&lt;!&ndash;                                                <Timer date="December 20, 2020" />&ndash;&gt;-->
                      <!--</div>-->

                    </div>
                  </div>
                </div>
              </div>
              <section class="tab-product m-0">
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <b-tabs card>
                      <b-tab title="Description" active>
                        <b-card-text v-html="productDetails.description"></b-card-text>
                      </b-tab>
                      <b-tab title="Video">
                        <b-card-text>
                          <div class="mt-3 text-center">
                            <iframe
                                v-if="productDetails.videos.length > 0"
                                width="560"
                                height="315"
                                :src="productDetails.videos[0].original_url"
                                allow="encrypted-media"
                                allowfullscreen
                            ></iframe>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <!--<b-tab title="Écrire une critique">-->
                      <!--<b-card-text>-->
                      <!--<form class="theme-form">-->
                      <!--<div class="form-row">-->
                      <!--<div class="col-md-12">-->
                      <!--<div class="media">-->
                      <!--<label>Rating</label>-->
                      <!--<div class="media-body ml-3">-->
                      <!--<div class="rating three-star">-->
                      <!--<i class="fa fa-star"></i>-->
                      <!--<i class="fa fa-star"></i>-->
                      <!--<i class="fa fa-star"></i>-->
                      <!--<i class="fa fa-star"></i>-->
                      <!--<i class="fa fa-star"></i>-->
                      <!--</div>-->
                      <!--</div>-->
                      <!--</div>-->
                      <!--</div>-->
                      <!--<div class="col-md-6">-->
                      <!--<label for="name">Name</label>-->
                      <!--<input-->
                      <!--type="text"-->
                      <!--class="form-control"-->
                      <!--id="name"-->
                      <!--placeholder="Enter Your name"-->
                      <!--required-->
                      <!--/>-->
                      <!--</div>-->
                      <!--<div class="col-md-6">-->
                      <!--<label for="email">Email</label>-->
                      <!--<input-->
                      <!--type="text"-->
                      <!--class="form-control"-->
                      <!--id="email"-->
                      <!--placeholder="Email"-->
                      <!--required-->
                      <!--/>-->
                      <!--</div>-->
                      <!--<div class="col-md-12">-->
                      <!--<label for="review">Review Title</label>-->
                      <!--<input-->
                      <!--type="text"-->
                      <!--class="form-control"-->
                      <!--id="review"-->
                      <!--placeholder="Enter your Review Subjects"-->
                      <!--required-->
                      <!--/>-->
                      <!--</div>-->
                      <!--<div class="col-md-12">-->
                      <!--<label for="review">Review Title</label>-->
                      <!--<textarea-->
                      <!--class="form-control"-->
                      <!--placeholder="Wrire Your Testimonial Here"-->
                      <!--id="exampleFormControlTextarea1"-->
                      <!--rows="6"-->
                      <!--&gt;</textarea>-->
                      <!--</div>-->
                      <!--<div class="col-md-12">-->
                      <!--<button class="btn btn-solid" type="submit">Submit YOur-->
                      <!--Review-->
                      <!--</button>-->
                      <!--</div>-->
                      <!--</div>-->
                      <!--</form>-->
                      <!--</b-card-text>-->
                      <!--</b-tab>-->
                    </b-tabs>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <relatedProduct :productTYpe="productTYpe" :productId="productDetails.id" />
      <!--<b-modal id="modal-1" size="md" centered hide-footer>-->
      <!--<template v-slot:modal-title>{{productDetails.title}}</template>-->
      <!--<img src="../../assets/images/size-chart.jpg" alt="size-chart" class="img-fluid"/>-->
      <!--</b-modal>-->
    </section>
    <Footer/>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Header from "../../components/header/appHeader";
import Footer from "../../components/footer/appFooter";
import Breadcrumbs from "../../components/widgets/breadcrumbs";
import productMixin from "../../mixins/productMixins";
import fluidPlayer from "fluid-player";

import VueHorizontal from "vue-horizontal";
import _ from 'lodash'
// import Timer from '../../components/widgets/timer'
import productSidebar from "../../components/widgets/product-sidebar";
import relatedProduct from '../../components/widgets/related-product'
export default {
  name: "productDetails",

  mixins: [productMixin],

  components: {
    Header,
    Footer,
    Breadcrumbs,
    // Timer,
    productSidebar,
    relatedProduct,
    VueHorizontal
  },
  data() {
    return {
      shareLinks: [
        {
          network: "email",
          color: "",
          icon: "fa fa-envelope",
          title: "Partager via Mail"
        },
        {
          network: "linkedin",
          color: "",
          icon: "fa fa-linkedin",
          title: "Partager sur LinkedIN"
        },
        {
          network: "facebook",
          color: "",
          icon: "fa fa-facebook",
          title: "Partager sur Facebook"
        },
        // {
        //   network: "messenger",
        //   color: '',
        //   icon: "fab fa-facebook-messenger",
        //   title: 'Partager sur Messenger'
        // },
        {
          network: "twitter",
          color: "",
          icon: "fa fa-twitter",
          title: "Partager sur Twitter"
        },
        {
          network: "whatsapp",
          color: "",
          icon: "fa fa-whatsapp",
          title: "Partager sur Whatsapp"
        },
        {
          network: "telegram",
          color: "",
          icon: "fa fa-telegram",
          title: "Partager sur Telegram"
        }
      ],


      player: null,

      selectVariantIndex: 0,
      selectedVariant: "",

      counter: 1,
      activeColor: "",
      selectedSize: "",
      qty: "",
      size: [],
      productTYpe: "",
      productId: "",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        freeMode: true
      },
      swiperOption1: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        centeredSlides: false
      }
    };
  },
  computed: {
    ...mapState({
      currency: state => state.products.currency
    }),
    ...mapGetters("products", ["productDetails", "products"]),

    swiperImages(){
      return this.selectedVariant && this.selectedVariant.images.length > 0 ? this.selectedVariant.images : this.productDetails.images
    },

    swiper() {
      return this.$refs.mySwiper.swiper;
    },


  },
  mounted() {
    this.$store.dispatch("products/getDetailProduct", this.$route.params.slug);

    this.player = fluidPlayer(this.$refs.myVideoPlayer);
    // // For displaying default color and size on pageload
    // this.uniqColor = this.productDetails.variants[0].color
    // this.sizeVariant(this.productDetails.variants[0].image_id)
    // // Active default color
    // this.activeColor = this.uniqColor
    // this.changeSizeVariant(this.productDetails.variants[0].size)
    // // related product type
    // this.relatedProducts()
  },

  watch:{
    productDetails:{
      immediate: true,
      handler: function(val) {
        if(val){
          if(val.type !== 'simple'){
            this.selectedVariant = val.variants[0]
          }else{
            this.selectedVariant = val
          }
          this.counter = this.selectedVariant.minimal_quantity
        }
      }
    }
  },

  methods: {
    productDescription(value , line){
      return _.truncate(_.replace(value,/<[^>]+>/g, ''), {
        'length': line,
        'separator': ' '
      });
    },

    priceCurrency: function() {
      this.$store.dispatch("products/changeCurrency");
    },
    addToWishlist: function(product) {
      this.$store.dispatch("products/addToWishlist", product);
    },
    discountedPrice(product) {
      const price = product.price - (product.price * product.discount / 100);
      return price;
    },
    // Related Products display
    relatedProducts() {
      this.productTYpe = this.productDetails.type;
      this.productId = this.productDetails.id;
    },
    // Display Unique Color
    Color(variants) {
      const uniqColor = [];
      for (let i = 0; i < Object.keys(variants).length; i++) {
        if (uniqColor.indexOf(variants[i].color) === -1) {
          uniqColor.push(variants[i].color);
        }
      }
      return uniqColor;
    },
    // add to cart
    addToCart: function(product, qty) {
      product.quantity = qty || 1;
      this.$store.dispatch("cart/addToCart", product);
      this.$router.push({path: '/produits'})
    },
    buyNow: function(product, qty) {
      product.quantity = qty || 1;
      this.$store.dispatch("cart/addToCart", product);
      this.$router.push("/checkout");
    },
    // Item Count
    increment() {
      this.counter++;
    },
    decrement() {
      if (+this.counter > +this.selectedVariant.minimal_quantity) this.counter--;
    },
    // Change size variant
    changeSizeVariant(variant) {
      this.selectedSize = variant;
    },
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    slideTo(id) {
      this.swiper.slideTo(id, 1000, false);
    },
    selectVariant(variant, idx) {
      this.selectVariantIndex = idx;
      this.selectedVariant = variant
    }
  },

  destroyed() {
    if (!this.player) {
      return;
    }

    this.player.destroy();
  }
};
</script>


<style scoped>
@import "~fluid-player/src/css/fluidplayer.css";

div.fluid-component, div.fluid-component > video {
  width: 100%;
  height: 100%;
}

.item {
  padding: 14px 6px;
}

.item:first-child {
  padding-left: 21px;
}

.item:last-child {
  padding-right: 21px;
}

.ring {
  width: 64px;
  height: 64px;
  border-radius: 32px;

  border: 3px solid transparent;
  background: #e2e8f0;

  cursor: pointer;
  transition: All 0.3s ease;
  overflow: hidden;
}

.ring:hover, .ring.active {
  /* This is a very simple trick to animation a ring of colors, you should go find a library for this*/
  transform: rotate(9deg) scale(1.05) translate(1px);
  animation: colors 1s ease infinite;
  background-size: 200% 200%;
  background-color: #663dff;
  border: 4px solid transparent;
  animation-direction: alternate;
  background-image: linear-gradient(319deg, #7d5fee 0%, #b72bff 33%, #ff2eb0 66%, #7eee40 100%);
}

@keyframes colors {
  0% {
    background-position: 10% 0
  }
  100% {
    background-position: 91% 100%
  }
}

.avatar {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

/* You can add your own button or you could just, */
/* Override default button design to make it smaller. */
/*.horizontal >>> .v-hl-btn svg {*/
/*padding: 2px;*/
/*height: 24px;*/
/*width: 24px;*/
/*}*/

</style>
